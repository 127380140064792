@import url('https://fonts.googleapis.com/css2?family=Google+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-container {
  height: 500px; /* Задаем высоту для контейнера Swiper */
}

.banner-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Обеспечиваем, чтобы изображения покрывали весь контейнер */
}

.swiper-container {
  height: 500px;
}

.banner-slide {
  position: relative;
  width: 100%;
  height: 100%;
}

.banner-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.banner-caption {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 5px;
}

.banner-caption h2 {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.banner-caption p {
  margin: 5px 0 0;
  font-size: 16px;
}

.contact-us-button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #4A90E2;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-us-button:hover {
  background-color: #357ABD;
}
